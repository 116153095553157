export const XS = 480;
// Small devices (landscape phones, 576px and down)
export const SM = 576;
// Medium devices (tablets, 768px and down)
export const MD = 768;
// Large devices (desktops, 1024 and down)
export const LG = 1024;
// Extra large devices (large desktops, 1200px and down)
export const XLG = 1200;

export const XXL = 1440;
